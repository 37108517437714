.verification-input .character {
  border-radius: 4px;
  color: var(--theme-color-highEmphasis, #333333);
  font-size: 20px;
  border-color: var(--theme-color-lowEmphasis, #bbbbbb);
  background-color: var(--theme-color-surface, #ffffff);
  height: 56px;
  width: 40px;
  line-height: 52px;
  outline: none;
  border-width: 1px;
  padding: 1px;
}

.verification-input .vi__wrapper {
  margin: auto;
}

.verification-input.error .character {
  border-color: var(--theme-color-alert, #e74627);
}

.verification-input.error .character-selected {
  border-color: var(--theme-color-alert, #e74627);
  outline: var(--theme-color-alert, #e74627) solid 1px;
}

.verification-input.success .character {
  border-color: var(--theme-color-success, #569a32);
}

.verification-input .character-selected {
  position: relative;
}

.verification-input.focused .character-selected {
  border-color: var(--theme-color-primary, #f6be00);
  outline: var(--theme-color-primary, #f6be00) solid 1px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.verification-input .character-selected::after {
  content: "";
  width: 2px;
  height: 30px;
  background-color: var(--theme-color-mediumEmphasis, #777777);
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 70%;
  animation: blink 1s infinite step-end;
}

.verification-input .character-selected:empty::after {
  left: 50%;
}

.verification-input .container {
  height: 56px;
}
